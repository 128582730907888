<template>
  <ContentWithSidebar @changeOption="showProjectByType" class="blog-wrapper my-projects-page" v-if="user">
    <!-- blogs -->
    <b-row id="list-my-project" class="blog-list-wrapper">
      <b-col md="3" v-for="project in projects.filter((p) => p.name.toLowerCase().includes(search_query.toLowerCase()))" :key="project.id">
        <b-card tag="article" no-body>
          <b-link>
            <b-img :src="imageSource(project.id)" alt="Project capture" class="card-img-top project-image" />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link class="blog-title-truncate text-body-heading project-title" :title="project.name">{{ project.name }}</b-link>
            </b-card-title>
            <b-card-text class="blog-content-truncate">{{ project.description }}</b-card-text>
            <b-media no-body>
              <b-media-aside vertical-align="center" class="mr-50">
                <b-avatar size="24" :src="user.avatar" />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-25">by</small>
                <small class="text-body">{{ user.full_name }}</small>
                <span class="text-muted ml-50 mr-25">|</span>
                <small class="text-muted">{{ project.updated | date }}</small>
                <b-badge pill class="byte-size-project" variant="light-info">{{ project.size | bytes }}</b-badge>
              </b-media-body>
            </b-media>
            <hr />
            <div :disabled="loadingProject" class="d-flex justify-content-between align-items-center">
              <button :disabled="loadingProject" type="button" @click="updateProject(project.id)" class="btn btn-primary btn-update">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="font-weight-bold">{{ $t('update') }}</span>
              </button>
              <b-link @click="deleteProject(project.id)">
                <b-button variant="flat-danger" class="btn-icon rounded-circle" style="padding: 5px"><feather-icon icon="Trash2Icon" class="cursor-pointer text-danger" /></b-button>
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row id="share-project" style="display: none" class="blog-list-wrapper">
      <b-col md="3" v-for="project in shareWithMe.filter((p) => p.name.toLowerCase().includes(search_query.toLowerCase()))" :key="project.id">
        <b-card tag="article" no-body>
          <b-link>
            <b-img :src="imageSource(project.id)" alt="Project capture" class="card-img-top project-image" />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link class="blog-title-truncate text-body-heading project-title" :title="project.name">{{ project.name }}</b-link>
            </b-card-title>
            <b-card-text class="blog-content-truncate">{{ project.description }}</b-card-text>
            <b-media no-body>
              <b-media-aside vertical-align="center" class="mr-50">
                <b-avatar size="24" :src="user.avatar" />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-25">by</small>
                <small class="text-body">{{ project.owner_name || user.full_name }}</small>
                <span class="text-muted ml-50 mr-25">|</span>
                <small class="text-muted">{{ project.updated | date }}</small>
                <b-badge pill class="byte-size-project" variant="light-info">{{ project.size | bytes }}</b-badge>
              </b-media-body>
            </b-media>
            <hr />
            <div class="d-flex justify-content-between align-items-center">
              <button type="button" @click="updateProject(project.id, true)" class="btn btn-primary btn-update">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="font-weight-bold">{{ $t('update') }}</span>
              </button>
              <b-link @click="deleteProject(project.id)">
                <div class="d-flex align-items-center text-danger">
                  <feather-icon icon="Trash2Icon" />
                </div>
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input class="search-project" placeholder="Search here" v-model="search_query" />
          <b-input-group-append class="cursor-pointer" is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->
      <div id="recently-project" class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">Recent Posts</h6>
        <b-media no-body class="mt-2" v-for="project in recentlyProjects" :key="project.id">
          <b-media-aside class="mr-2">
            <b-link @click="updateProject(project.id)">
              <b-img :src="imageSource(project.id)" alt="Project capture" width="100" rounded height="70" />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading" @click="updateProject(project.id)">{{ project.name }}</b-link>
            </h6>
            <span class="text-muted mb-0">{{ project.updated | date }}</span>
          </b-media-body>
        </b-media>
      </div>

      <div id="recently-share-project" style="display: none !important" class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">Recent Posts</h6>
        <b-media no-body class="mt-2" v-for="project in recentlyShareProjects" :key="project.id">
          <b-media-aside class="mr-2">
            <b-link @click="updateProject(project.id)">
              <b-img :src="imageSource(project.id)" alt="Project capture" width="100" rounded height="70" />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading" @click="updateProject(project.id)">{{ project.name }}</b-link>
            </h6>
            <span class="text-muted mb-0">{{ project.updated | date }}</span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">Categories</h6>

        <div class="d-flex justify-content-start align-items-center mb-75" v-for="(type, index) in types" :key="index">
          <b-link @click="selectType(type.name)">
            <b-avatar rounded size="32" :variant="typeSelected === type.name ? type.activeVariant : type.variant" class="mr-75">
              <component :is="type.icon" :style="{ width: '16px', height: '16px' }" />
            </b-avatar>
          </b-link>
          <b-link @click="selectType(type.name)">
            <div class="blog-category-title text-body">{{ type.name }}</div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
    </div>
    <!--/ sidebar -->
  </ContentWithSidebar>
</template>

<script>
const dayjs = require('dayjs')
import { ECOPLOT_API } from '@/constants/urls'
import CompressMixin from '@/mixins/CompressMixin.js'
import ContentWithSidebar from '@/@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import fetch from 'node-fetch'
const axios = require('axios')

export default {
  components: { ContentWithSidebar },
  mixins: [CompressMixin],
  filters: {
    date(value) {
      return dayjs(value).format('MMM DD, YYYY')
    },
    bytes(value) {
      if (value === 0) return '0 Bytes'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(value) / Math.log(k))
      return parseFloat((value / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.name !== 'projects') return
        this.getProjects()
        this.getShareWithMe()
      },
      { immediate: true }
    )
  },
  data() {
    return {
      projects: [],
      shareWithMe: [],
      search_query: '',
      types: [
        { name: 'Math', variant: 'light-success', activeVariant: 'success', icon: require('/static/images/templates/math.svg').default },
        { name: 'Virus', variant: 'light-danger', activeVariant: 'danger', icon: require('/static/images/templates/virus.svg').default },
        { name: 'Japan', variant: 'light-primary', activeVariant: 'primary', icon: require('/static/images/templates/japan.svg').default },
        { name: 'Typhoon', variant: 'light-info', activeVariant: 'info', icon: require('/static/images/templates/typhoon.svg').default },
      ],
      typeSelected: null,
      loadingProject: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
    recentlyProjects() {
      let clone = [...this.projects]
      clone.sort((a, b) => {
        return new Date(dayjs(a.updated).valueOf()) < new Date(dayjs(b.updated).valueOf()) ? 1 : -1
      })
      return clone.slice(0, 5)
    },
    recentlyShareProjects() {
      let clone = [...this.shareWithMe]
      clone.sort((a, b) => {
        return new Date(dayjs(a.updated).valueOf()) < new Date(dayjs(b.updated).valueOf()) ? 1 : -1
      })
      return clone.slice(0, 5)
    },
  },
  methods: {
    showProjectByType(value) {
      if (value === 'myProject') {
        document.querySelector('#list-my-project').style.display = 'flex'
        document.querySelector('#share-project').style.display = 'none'
        document.querySelector('#recently-project').style.display = 'block'
        document.querySelector('#recently-share-project').style.display = 'none'
      } else {
        document.querySelector('#list-my-project').style.display = 'none'
        document.querySelector('#share-project').style.display = 'flex'
        document.querySelector('#recently-project').style.display = 'none'
        document.querySelector('#recently-share-project').style.display = 'block'
      }
    },
    imageSource(id) {
      return `${ECOPLOT_API}/static/${id}.png`
    },
    selectType(type) {
      if (type === this.typeSelected) {
        this.typeSelected = null
      } else {
        this.typeSelected = type
      }
    },
    async getProjects() {
      this.projects = []

      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/projects/`, { headers: { Authorization: `Token ${token}` } })
      this.projects = response.data
    },
    async getShareWithMe() {
      this.shareWithMe = []

      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/shared-projects/`, { headers: { Authorization: `Token ${token}` } })
      this.shareWithMe = response.data
    },
    updateProject(id, isShare = false) {
      if (this.loadingProject) return
      this.loadingProject = true
      this.fetchMeta(id)
      this.fetchData(id, isShare)
      this.loadingProject = false
    },
    async fetchMeta(id) {
      let token = localStorage.getItem('django-authentication-token')
      let avatar = await fetch(this.imageSource(id))
      let bufferImage = Buffer.from(await avatar.arrayBuffer())
      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/projects/${id}/`, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
      this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', bufferImage)
    },
    async fetchData(id, isShare = false) {
      let response = await axios.get(`${ECOPLOT_API}/file/${id}/download/`, { responseType: 'blob' })

      this.$router.push({ path: '/map' }).then(() => {
        // wait 0.5s for page transition
        setTimeout(async () => {
          await this.extractProject(new File([response.data], 'filename'))
          if (isShare) {
            this.$store.commit('ecoplot/UPDATE_IS_PROJECT_SHARE', true)
          }
        }, 500)
      })
    },
    async deleteProject(id) {
      let result = await this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this project.', {
        title: 'Please Confirm',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })

      if (!result) return

      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      try {
        await axios.delete(`${ECOPLOT_API}/projects/${id}/`, { headers: { Authorization: `Token ${token}` } })
        this.getProjects()
        this.getShareWithMe()
        // this.$router.push({ path: '/map' })
      } catch (error) {
        this.$store.commit('ecoplot/SET_ERROR', { message: 'You do not have permission to perform this action' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-blog.scss';
</style>

<style scoped>
.my-projects-page .app-logo {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.my-projects-page .project-image {
  height: 200px;
  object-fit: cover;
}

.my-projects-page .project-title {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.search-project {
  height: 42px;
}
.byte-size-project {
  float: right;
  font-weight: 100;
}
.btn-update {
  padding: 5px;
}
</style>
